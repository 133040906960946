<template>
  <div class="container-fluid">
    <div class="" v-if="de.id == null">...loading</div>
    <div class="row" else>
      <div class="col-md-12 mt-5 mb-4">
        <div
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Meta information
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3 d-flex justify-content-center">
                  <a href="#">
                    <div
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        de.fullPhotoUrl ? de.fullPhotoUrl : '/assets/img/profilMan.jpg'
                      }); height: 120px; width: 120px;`"
                    ></div>
                  </a>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Inscrit le</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ $moment(demandeur.lastLogin).format("DD-MM-YYYY") }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Statut</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <div
                        @click.stop="showDemandeur(de)"
                        class="d-flex justify-content-cent mb-3"
                        v-if="de.azoli"
                      >
                        <azoli-status-badge :de="de" />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">{{ demandeur.matricule }}</div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <qrcode-vue :value="'00000' + de.id" />
                    <span>Ref:{{ "00000" + de.id }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Agent enregistreur
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nom</h6>
                </div>
                <div v-if="demandeur.creePar" class="col-sm-7 text-secondary">
                  {{ demandeur.creePar.nom }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Prénom(s)</h6>
                </div>
                <div v-if="demandeur.creePar" class="col-sm-7 text-secondary">
                  {{ demandeur.creePar.prenom }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Numéro</h6>
                </div>
                <div v-if="demandeur.creePar" class="col-sm-7 text-secondary">
                  {{ demandeur.creePar.phone }}
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                I. Identification bénéficiaire
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nom</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.nom }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Prénom(s)</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.prenom }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Date de naissance (JJ/MM/AAAA)(pour ceux qui sont nés vers, écrire
                    31/12/AAAA)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ $moment(demandeur.dateNais).format("DD/MM/YYYY") }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Dispose de son Certificat d'identification Personnelle ou de sa carte
                    d'identité biométrique?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge :value="de.hasNip" />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Numéro NPI</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.npi || "-" }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Téléphone</h6>
                </div>
                <div class="col-sm-12">
                  <table class="table table-borderless">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Principal</th>
                        <th scope="col">Whatsapp</th>
                        <th scope="col">Alternatif</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ demandeur.phone || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.whatsapp || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.phoneAlternatif || "-" }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Courriel (email)</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.email }}</div>
              </div>
              <hr />
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                II. Domicile
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Département</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ (demandeur.departement && demandeur.departement.nom) || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Commune</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ (demandeur.commune && demandeur.commune.nom) || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Arrondissement</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ (demandeur.arrondissement && demandeur.arrondissement.nom) || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Village/Quartier de ville</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ (demandeur.ville && demandeur.ville.nom) || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <table class="table table-borderless">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Rue</th>
                        <th scope="col">Carrée</th>
                        <th scope="col">Maison</th>
                        <th scope="col">Hameau</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ demandeur.rue || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.carre || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.maison || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.hameau || "-" }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Info inscription
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Métier que tu as appris</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.metierAppris && demandeur.metierAppris.libelle }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Description du métiers appris</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.metierApprisDescription }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Métier (s) que tu veux faire</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.metierDesire && demandeur.metierDesire.libelle }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Description du métier recherché choisis</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.metierDesireDescription }}
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                III. Informations sociodémographiques
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Sexe</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.sexe }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Age (généré automatiquement à partir de la date de naissance)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ de.dateNais | age }} ans</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Nombre d’enfants de moins de trois mois (mettre 0, si aucun)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.nombreEnfantsMoins || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Nombre d’enfants âgés de trois mois à trois ans inclus.
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.nombreEnfantsPlus || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Etes-vous actuellement en état de grossesse ?</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.ifu }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Langues nationales parlées</h6>
                </div>
                <div class="col-sm-12 text-secondary">
                  <div class="mb-5 mt-2">
                    <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">MaitriseLangues</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->

                    <template v-if="de.maitriseLangues && de.maitriseLangues.length != 0">
                      <template v-for="(maitrise, index) in de.maitriseLangues">
                        <div
                          :key="index"
                          class="border py-2 px-2 d-flex justify-content-between mb-2 align-items-center"
                        >
                          <div class="">
                            <h6 class="mb-0">
                              <span>Langue: {{ maitrise.libelle }} </span
                              >{{ maitrise.ethnie.nom }}
                            </h6>
                            <!-- <small>Niveau: {{niveauAlphabetionsMap[maitrise.niveauAlphabetisation]}}</small> -->
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="card mb-3 border-0">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                IV. Niveau d’instruction
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Etes-vous allé(e) à l’école primaire ou secondaire ? (Oui ou Non)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge :value="demandeur.estInstruit" />
                </div>
              </div>
              <hr v-show="demandeur.estInstruit" />
              <div class="row" v-show="demandeur.estInstruit">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Etes-vous actuellement inscrit(e) dans une école primaire ou
                    secondaire pour l’année en cours ? (Attention à ne pas confondre avec
                    un centre de formation technique ou professionnelle)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge :value="demandeur.dejaInscrit || false" />
                </div>
              </div>
              <hr v-show="demandeur.estInstruit" />
              <div class="row" v-show="demandeur.estInstruit">
                <div class="col-sm-5">
                  <h6 class="mb-0">Dernier niveau scolaire achevé</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{
                    (demandeur.niveauInstructionEnquete &&
                      demandeur.niveauInstructionEnquete.description) ||
                    "-"
                  }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Niveau d’alphabétisation en français</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ niveauAlphabetionsMap[demandeur.niveauAlphabetisationFrancais] }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Niveau d’alphabétisation en langue locale</h6>
                </div>
                <div class="col-sm-12 text-secondary px-2">
                  <template v-if="de.maitriseLangues && de.maitriseLangues.length != 0">
                    <!-- <template v-for="(maitrise, index) in de.maitriseLangues">
                      <div :key="index"  class="border mt-3 py-2 px-2 d-flex justify-content-between mb-2 align-items-center">
                        <div class="">
                          <h6 class="mb-0"><span>Langue: {{maitrise.libelle}} </span>{{ maitrise.ethnie.nom }}</h6>
                          <small>Niveau: {{niveauAlphabetionsMap[maitrise.niveauAlphabetisation]}}</small>
                        </div>
                      </div>
                    </template> -->
                    <table class="table table- striped mt-3">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Langue</th>
                          <th scope="col">Niveau</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(maitrise, index) in de.maitriseLangues" :key="index">
                          <td>
                            <span> {{ maitrise.ethnie.nom || "-" }}</span>
                          </td>
                          <td>
                            <span>
                              {{
                                niveauAlphabetionsMap[maitrise.niveauAlphabetisation] ||
                                "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Disposez-vous d’une qualification professionnelle ? (Oui ou Non)
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge
                    :value="demandeur.hasQualificationProfessionnelle || false"
                  />
                </div>
              </div>
              <hr />
              <div class="row" v-if="de.metierQualification">
                <div class="col-sm-5">
                  <h6 class="mb-0">Si « Oui » laquelle ?</h6>
                </div>
                <div class="col-sm-12 text-secondary">
                  <table class="table table- striped mt-3">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Qualification</th>
                        <th scope="col">Métier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ de.metierQualification.libelle || "-" }}</span>
                        </td>
                        <td>
                          <span>
                            {{ de.qualificationProfessionnelle.libelle || "-" }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr v-if="de.metierQualification" />

              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Suivez-vous actuellement une formation professionnelle ?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge
                    :value="demandeur.hasFormationProfessionnelle || false"
                  />
                </div>
              </div>
              <div class="row" v-if="de.formationEncours">
                <div class="col-sm-5">
                  <h6 class="mb-0">Si « Oui » laquelle ?</h6>
                </div>
                <div class="col-sm-12 text-secondary">
                  <table class="table table- striped mt-3">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Qualification</th>
                        <th scope="col">Métier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ de.formationEncours.libelle || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ de.formationProfessionnelle.libelle || "-" }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr v-if="de.metierQualification" />
              <hr v-show="demandeur.encoursDeFormation" />
              <div class="row" v-show="demandeur.encoursDeFormation">
                <div class="col-sm-5">
                  <h6 class="mb-0">Dans quel métier?</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.metierAppris && demandeur.metierAppris.libelle }}
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-0">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">V. Situation emploi</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Au cours de la semaine dernière (7 derniers jours), avez-vous
                    travaillé ne serait-ce qu'une heure ?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge
                    :value="demandeur.travaillerAuMoinsUneHeureSemainePasse"
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Bien que vous ayez déclaré ne pas avoir travaillé la semaine dernière,
                    avez-vous réalisé l'une des activités suivantes la semaine dernière, à
                    domicile ou à l'extérieur, pour aider la famille
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{
                    de.serviceRenduSemaineDerniere
                      ? de.serviceRenduSemaineDerniere.libelle
                      : "-"
                  }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Bien que vous n'ayez pas travaillé la semaine dernière, avez-vous un
                    emploi ? Oui ou Non
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge :value="de.hasEmploi" />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Combien d'heures avez-vous consacrées à votre emploi principal au
                    cours de la semaine dernière ?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ de.heureConsacreAEmploiPrincipal }} heures
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Souhaiteriez-vous travailler plus ?</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <yes-or-no-badge :value="de.travaillerPlus" />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Actuellement le salaire minimum mensuel est de 52 000 FCFA, quel est
                    le montant de votre rémunération par rapport au salaire minimum ?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ plageSalairesMap[de.plageSalaireActuel] || "-" }}
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-0">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                VI. Chômage et recherche emploi
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">
                    Depuis quand êtes-vous sans emploi et à la recherche d'un emploi ?
                  </h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.datePerteTravail | formatDate }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Pourquoi recherchez-vous un emploi ?</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ raisonEmploisMap[demandeur.raisonRechercheEmploi] }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Recherchez-vous un emploi salarié ou indépendant ?</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ typeEmploisMap[demandeur.typeEmploi] || "-" }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Qualification pour les métiers retenus</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{
                    demandeur.metierQualification
                      ? demandeur.metierQualification.libelle
                      : "-"
                  }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Qualification pour les métiers retenus</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{
                    demandeur.metierQualification
                      ? demandeur.metierQualification.libelle
                      : "-"
                  }}
                </div>
              </div>
              <hr />
            </div>
          </div>

          <div class="card mb-3 border-0">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Si auto-emploi, veuillez préciser l’activité et le métier
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Activité (voir code NAEMA)</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <span class="" v-if="demandeur.metierAppris">{{
                    demandeur.metierAppris.famille.libelle
                  }}</span>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Métier</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <span class="" v-if="demandeur.metierAppris">{{
                    demandeur.metierAppris.libelle
                  }}</span>
                </div>
              </div>
              <hr />
              <div class="row" v-if="de.typeEmploi == 'AUTO_EMPLOI'">
                <div class="col-sm-12">
                  <h6 class="mb-0">Dans quel(s) métier(s) voulez-vous entreprendre?</h6>
                </div>
                <div class="col-sm-12 px-3">
                  <template v-if="de.metierIndependants.length != 0">
                    <ol class="mt-2 pt-2">
                      <li v-for="(emp, index) in de.metierIndependants" :key="index">
                        <div class="mb-4 border-bottom bg-light py-2 px-2">
                          <div
                            class="d-flex justify-content-between mb-2 align-items-center"
                          >
                            <div class="">
                              <h5 class="fw-bold text-uppercase mb-0">
                                Idée: {{ emp.idee }}
                              </h5>
                              <small class="text-muted"
                                >Metier: {{ emp.metier.libelle }}</small
                              >
                            </div>
                          </div>
                          <div>
                            Qualifie: <yes-or-no-badge :value="emp.estQualifie" />
                          </div>
                          <div v-if="emp.estQualifie">
                            {{ emp.qualificationProfessionnelle.libelle }}
                          </div>
                        </div>
                      </li>
                    </ol>
                  </template>
                </div>
              </div>
              <div class="row" v-if="de.typeEmploi == 'SALARIE'">
                <div class="col-sm-12">
                  <h6 class="mb-0">Dans quel(s) métier(s) voulez-vous être salarié?</h6>
                </div>
                <div class="col-sm-12 px-3">
                  <template v-if="de.metierSalaries.length != 0">
                    <ol class="mt-2 pt-2">
                      <li v-for="(emp, index) in de.metierSalaries" :key="index">
                        <div class="mb-4 border-bottom bg-light py-2 px-2">
                          <div
                            class="d-flex justify-content-between mb-2 align-items-center"
                          >
                            <div class="">
                              <h5 class="fw-bold text-uppercase mb-0">
                                {{ emp.metier.famille.libelle }}
                              </h5>
                              <small class="text-muted">{{ emp.metier.libelle }}</small>
                            </div>
                            <div>
                              <!-- <a @click.prevent="updateMetierIndependant(emp)" class="btn btn-success rounded-0 btn-sm"><i class="bi bi-pencil-square"></i></a>
                            <a @click.prevent="deleteMetierIndependant(emp)" class="btn btn-danger rounded-0 btn-sm"><i class="bi bi-trash"></i></a> -->
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <span
                              >Qualifie: <yes-or-no-badge :value="emp.estQualifie"
                            /></span>
                            <span v-if="emp.moyenQualification">{{
                              emp.moyenQualification.libelle
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </template>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Comment avez-vous entendu parler de Azôli</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  <span v-if="de.sourceInfo && de.sourceInfo.code != 'AUTRES'">{{
                    de.sourceInfo.libelle
                  }}</span>
                  <span v-if="de.sourceInfo && de.sourceInfo.code == 'AUTRES'">{{
                    de.autreSourceInfo
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="card-booking-completed pa-5 mb-5 mx-5 d-flex flex-column justify-content-center"
          :height="550"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
import QrcodeVue from "qrcode.vue";
import AzoliStatusBadge from "@/components/common/AzoliStatusBadge.vue";

export default {
  props: ["demandeurId"],
  components: {
    YesOrNoBadge,
    QrcodeVue,
    AzoliStatusBadge,
  },
  data() {
    return {
      demandeur: {},
    };
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    if (this.demandeurId) {
      this.fetchDossier({
        demandeurId: this.demandeurId,
      });
    }
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.demandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      raisonEmploisMap: "setting/raisonEmploisMap",
      plageSalairesMap: "setting/plageSalairesMap",
      typeEmploisMap: "setting/typeEmploisMap",
      niveauAlphabetionsMap: "instruction/niveauAlphabetionsMap",
      niveauLanguesMap: "instruction/niveauLanguesMap",
    }),
    de() {
      return this.demandeur;
    },
  },
  methods: {
    ...mapActions({
      fetchDossier: "demandeur/fetchDossier",
    }),
  },
};
</script>

<style>
.border {
  border: 1px solid #503;
}
</style>
